var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":_vm.$vuetify.theme.isDark}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"text":"","disabled":!_vm.adminRole && !_vm.staffAddRole,"outlined":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-plus-circle")]),_vm._v(" اضافة موظف جديد ")],1),_c('v-divider',{staticClass:"mx-4 my-1",attrs:{"vertical":""}}),_c('v-spacer'),_c('search-in-data-table')],1),_c('v-data-table',{staticClass:"elevation-1 zaid",attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":_vm.itemRowBackground,"search":_vm.search,"loading":_vm.loading,"no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdDate))+" ")])]}},{key:"item.serviceType",fn:function(ref){
var item = ref.item;
return [(item.serviceType == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"green"}},[_vm._v(" تنظيف ")]):_vm._e(),(item.serviceType == 2)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"blue"}},[_vm._v(" صيانة ")]):_vm._e(),(item.serviceType == 3)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"error"}},[_vm._v(" مشرف ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.status ? "متوقف عن الخدمة" : "مستمر")+" ")])]}},{key:"item.modifiedUserGuid",fn:function(ref){
var item = ref.item;
return [(item.modifiedUserGuid == null)?_c('span',[_vm._v(" ليس بعد ")]):_vm._e()]}},{key:"item.modifiedDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.modifiedDate))+" ")]),(item.modifiedDate == null)?_c('span',{staticClass:"error--text"},[_vm._v(" ليس بعد ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","fab":"","disabled":!_vm.adminRole && !_vm.staffEditRole,"depressed":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","fab":"","disabled":!_vm.adminRole && !_vm.staffDeleteRole,"depressed":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])])]}}],null,true)}),_c('v-divider'),_c('v-pagination',{staticClass:"py-6",attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('Dialog',{ref:"dialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }