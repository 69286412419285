<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="50%" persistent="">
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        {{
                            $store.state.bodyDetails.dialogType == "add"
                                ? "اضافة"
                                : "تعديل"
                        }}
                        موظف جديد
                        <div class="mt-n2">
                            <small class="grey--text" style="font-size: 12px">
                                موظف صيانة, موظف مشرف, موظف تنظيف
                            </small>
                        </div>
                    </v-toolbar-title>

                    <v-spacer />
                    <v-btn @click="close()" color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" md="8" class="pl-2">
                                <small class="font-weight-bold">
                                    الاسم الكامل
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.fullName"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="  أسم الموظف الكامل "
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="4" class="pl-2">
                                <small>
                                    نوع الخدمة
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-select
                                    v-model="body.serviceType"
                                    :items="serviceType"
                                    item-text="name"
                                    item-value="value"
                                    filled
                                    outlined
                                    label="أختار نوع الخدمة"
                                    dense
                                >
                                </v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                                class="pl-2"
                                v-if="
                                    $store.state.bodyDetails.dialogType ==
                                        'edit'
                                "
                            >
                                <small>
                                    حالة الموظف
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-select
                                    v-model="body.status"
                                    :items="status"
                                    item-text="name"
                                    item-value="value"
                                    filled
                                    outlined
                                    label="حالة الموظف ةل ةو مستمر , متوقف "
                                    dense
                                >
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="12">
                                <small>
                                    اكتب وصفاً لةذا الموظف
                                    <span class="grey--text">
                                        اي ملاحضات في خصوص ةذا الموظف
                                    </span>
                                </small>
                                <v-textarea
                                    filled
                                    class="mt-1"
                                    v-model="body.notes"
                                    required
                                    outlined
                                    label="لا توجد ملاحضات"
                                    value="لا توجد ملاحضات"
                                    dense
                                    color="secondary"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" md="12" class="mt-2">
                                <v-btn
                                    type="submit"
                                    :disabled="
                                        !body.fullName || !body.serviceType
                                    "
                                    :loading="loading"
                                    x-large
                                    color="primary"
                                >
                                    حفظ
                                </v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                >
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import { mapState } from "vuex";
export default {
    components: {},

    data: () => ({
        valid: true,
        body: {},
        loading: false,
        ...validations,
        serviceType: [
            {
                name: "تنظيف",
                value: 1
            },
            {
                name: "صيانة",
                value: 2
            },
            {
                name: "مشرف",
                value: 3
            }
        ],
        status: [
            {
                name: "مستمر في الخدمة",
                value: false
            },
            {
                name: "متوقف عن الخدمة",
                value: true
            }
        ]
    }),
    computed: {
        ...mapState(["bodyDetails"])
    },

    created() {
        this.body = {};
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            this.body = this.bodyDetails;
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },
        addItem() {
            this.loading = true;
            this.$GenericService
                .create("staff", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        editItem() {
            this.loading = true;
            const data = Object.entries(this.body).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
            ); //remove null fields

            this.$GenericService
                .update(`staff/edit/${this.body.id}`, data)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        close() {
            this.$store.dispatch("setDialog");
            this.body = {};
            this.resetValidation();
            this.$store.dispatch("setBodyDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
